import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Consts} from "./Consts";
import {ApiService} from "./api.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent  implements OnInit {
  title = 'applicationform';
  username = ""
  role = ""
  isMenuCollapsed = true
  constructor(private api: ApiService, private router: Router) {

  }

  ngOnInit() {
    console.log("AppComponent ngOnInit" )
    this.username = localStorage.getItem(Consts.USERNAME) || ""
    console.log("AppComponent ngOnInit "+this.username )
    this.role = localStorage.getItem(Consts.ROLE) || ""

    if(this.username !== "") {
      this.api.me().subscribe(res => {
        if (res['success']) {
          // this.router.navigate(['/appform'])
        }
        else {
          localStorage.removeItem(Consts.TOKEN)
          localStorage.removeItem(Consts.ROLE)
          localStorage.removeItem(Consts.USERNAME)
          this.username = ""
          this.router.navigate(['/login'])
        }
      })
    }
    // else this.router.navigate(['/login'])

    this.api.localita().subscribe(res => {
      if (res['success']) {
        Consts.regioni = res['items']
      }
      console.log(res['items'])
    })
  }
  logout() {

    localStorage.removeItem(Consts.TOKEN)
    localStorage.removeItem(Consts.ROLE)
    localStorage.removeItem(Consts.USERNAME)
    this.username = ""
    this.role = ""
    location.reload()
  }

}
