import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationComponent } from './registration/registration.component';
import { AppformComponent } from './appform/appform.component';
import { ConfermaInvioComponent } from './dialog/conferma-invio/conferma-invio.component';

import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {TokenInterceptor} from "./interceptor";
import {AuthGuardService} from "./auth-guard";
import {DateAdapter} from "@angular/material/core";
import { HomeComponent } from './home/home.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { AccountComponent } from './account/account.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';

import { PathLocationStrategy, LocationStrategy } from '@angular/common';
import {RouterModule} from "@angular/router";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    AppformComponent,
    ConfermaInvioComponent,
    HomeComponent,
    AccountComponent,
    PrivacyComponent,
    AlertDialogComponent,
    ResetpasswordComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        MatDialogModule,
        MatButtonModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule
    ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
  },
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    AuthGuardService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
