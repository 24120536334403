import { Component, OnInit } from '@angular/core';
import {Consts} from "../Consts";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  // title = 'applicationform';
  username = ""
  role = ""

  constructor() { }

  ngOnInit(): void {
    this.username = localStorage.getItem(Consts.USERNAME) || ""
    this.role = localStorage.getItem(Consts.ROLE) || ""
    if(this.username != '') {

    }
  }

  logout() {

    localStorage.removeItem(Consts.TOKEN)
    localStorage.removeItem(Consts.ROLE)
    localStorage.removeItem(Consts.USERNAME)
    this.username = ""
    this.role = ""
  }

}
