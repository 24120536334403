import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {RegistrationComponent} from "./registration/registration.component";
import {AppformComponent} from "./appform/appform.component";
import {HomeComponent} from "./home/home.component";
import {AccountComponent} from "./account/account.component";
import {PrivacyComponent} from "./privacy/privacy.component";
import {ResetpasswordComponent} from "./resetpassword/resetpassword.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'registrazione',
    component: RegistrationComponent
  },
  {
    path: 'appform',
    component: AppformComponent
  },
  {
    path: 'account',
    component: AccountComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'reset_password/:token',
    component: ResetpasswordComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
