import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../api.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Appform} from "../model/appform";

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.less']
})
export class AlertDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    private api: ApiService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public contenuto: {
      titolo:string, testo:string, testo2:string,  hasCancel:boolean,
      hasConfirmProject:boolean,
      hasLoading:boolean,
      item:Appform
    }) {}

  ngOnInit(): void {
    this.contenuto.hasConfirmProject
  }

  actionOk(){
    if( this.contenuto.hasConfirmProject){

      this.api.convalida(this.contenuto.item).subscribe(res => {
        console.log(res);
        location.reload()
        // do something, if upload success
      }, error => {
        console.log(error);
        location.reload()
      });

    }
  }

}
