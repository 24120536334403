import {Component, Inject, OnInit} from '@angular/core';
import {ApiService} from "../../api.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-conferma-invio',
  templateUrl: './conferma-invio.component.html',
  styleUrls: ['./conferma-invio.component.less']
})
export class ConfermaInvioComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfermaInvioComponent>,
    private api: ApiService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA
    ) public evento: BigInteger) {}

  ngOnInit(): void {
  }

}
