import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ApiService} from "../api.service";
import {Router} from "@angular/router";
import {Consts} from "../Consts";
import {Appform} from "../model/appform";
import {Regione} from "../model/regione";
import {Observable} from "rxjs";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-appform',
  templateUrl: './appform.component.html',
  styleUrls: ['./appform.component.less']
})
export class AppformComponent implements OnInit {

  iscrizioni_chiuse = false;
  business_loader = false
  team_loader = false
  altro_loader = false
  isDocumentoIdentita = false
  isPresentazione = false
  loader_pdf = false
  id_loader = 0
  file_documento_identita: File
  file_presentazione: File
  appform:Appform = <Appform>{
    idea:"",
    descrizione:"",
    innovazione:"",
    mercato:"",
    sociale:"",
    realizzabilita:"",
    descrizione_team:"",
    investimenti:"",
    fondi:"",
    correlazione_descrizione:"",
    iniziative:"",
    business_plan:[],
    business_model:[],
    team:[],
    altro:[],
  }

  cur_team_nome = ""
  cur_team_cognome = ""
  cur_team_ruolo = ""
  cur_team_categoria = ""
  cur_team_ente = ""
  username = ""
  sent = ""

  cb1: boolean = false;
  cb2: boolean = false;
  cb3: boolean = false;
  constructor(private api: ApiService, private router: Router, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.username = localStorage.getItem(Consts.USERNAME) || ""
    if(this.username === null || this.username === "") {
      this.router.navigate(['/login'])
    }
    this.reload();
  }

  reload(){
    this.business_loader = false
    this.team_loader = false
    this.altro_loader = false
    this.api.getCurrentForn().subscribe(res => {
      if (res['success']) {
        this.appform = res['items']
        if(this.appform.documento_identita) {
          this.isDocumentoIdentita = true
        }
        if (this.appform.presentazione) {
          this.isPresentazione = true
        }
        if (res['chiuso']) {
          this.iscrizioni_chiuse = true
          this.appform.status = 1
        }
        this.sent = localStorage.getItem(Consts.SENT) || ""
        if(this.appform.status == 1 && this.sent == ''){
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '450px',
            data: {
              titolo: "Application form compilata",
              testo: "Riceverai una Mail con il Codice del tuo progetto che ti chiediamo di conservare e utilizzare in tutte le comunicazioni.",
              testo2: "Ricorda ora di inviare tutti i documenti necessari alla PEC cciaa@pec.chpe.camcom.it, ai fini dell'ammissione alla competition.",
              hasCancel: false,
              hasConfirmProject: false
            }
          });
          localStorage.setItem(Consts.SENT, "sent")
        }
        else if(this.appform.status == 0){
          localStorage.removeItem(Consts.SENT)
        }
      }
    })
  }

  reloadFiles(){
    this.business_loader = false
    this.team_loader = false
    this.altro_loader = false
    this.api.getCurrentForn().subscribe(res => {
      if (res['success']) {
        var cur = res['items'];
        this.appform.business_plan =cur.business_plan
        this.appform.team =cur.team
        this.appform.altro =cur.altro
        this.appform.business_model =cur.business_model
      }
    })
  }

  isEnabled():boolean{
    return this.appform.status == 0;
  }

  handlerDocumento(event: any) {
    this.file_documento_identita = event.target.files[0]
  }

  handlerPresentazione(event2: any)  {
    this.file_presentazione = event2.target.files[0]
  }

  onSubmit() {

    // console.log(this.appform)
    if (this.file_documento_identita) {
      this.api.postFile(this.file_documento_identita, 'documento_identita').subscribe()
    }

    if (this.file_presentazione) {
      this.api.postFile(this.file_presentazione, 'presentazione').subscribe()
    }

    this.api.inviaForm(this.appform).subscribe((res) => {

      if (res.success) {
        this.reload();
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '450px',
          data: {
            titolo:"Progetto salvato",
            testo:"Progetto salvato con successo.",
            testo2:"Puoi modificare il progetto prima di confermare.",
            hasCancel:false,
            hasConfirmProject:false
          }
        });
      }
      else{
        //messaggio di errore
        if (res.chiuso) {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '450px',
            data: {
              titolo: "Attenzione",
              testo: "Iscrizioni chiuse.",
              testo2: "",
              hasCancel: false,
              hasConfirmProject: false
            }
          });
        }
        else {
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '450px',
            data: {
              titolo: "Attenzione",
              testo: "Errore nel salvataggio del progetto.",
              testo2: "",
              hasCancel: false,
              hasConfirmProject: false
            }
          });
        }
      }
    })

  }
  // fileToUpload: File | null = null;
  handleFileInput(files: FileList, type:string) {
    var fileToUpload = files.item(0);



    if(!this.checkFileType(fileToUpload.type, type)){
      // alert("file non supportato")
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"File non supportato.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    if(fileToUpload.size > 15000000) {
      // alert("file troppo grande")
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"File troppo grande. Max 15 MB",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return;
    }


    const dialogRefLoading = this.dialog.open(AlertDialogComponent, {
      width: '450px',
      data: {
        titolo:"Caricamento file in corso...",
        testo:"",
        testo2:"",
        hasCancel:false,
        hasConfirmProject:false,
        hasLoading:true
      }
    });

    this.api.postFile(fileToUpload, type).subscribe(res => {
      console.log(res);
      // this.business_loader = false
      // this.team_loader = false
      // this.altro_loader = false
      dialogRefLoading.close()
      this.reloadFiles();
      // do something, if upload success
    }, error => {
      console.log(error);
      // this.business_loader = false
      // this.team_loader = false
      // this.altro_loader = false
      dialogRefLoading.close()
      this.reloadFiles();
    });

    this.business_loader = false
    this.team_loader = false
    this.altro_loader = false
  }


  addTeamClick(){
    if(this.cur_team_nome == "" || this.cur_team_cognome == "" || this.cur_team_ruolo == ""
      || this.cur_team_categoria == ""
      || this.cur_team_ente == ""
    ){
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Riempi i campi nome, cognome e ruolo.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    this.appform.team.push({
      nome:this.cur_team_nome,
      cognome:this.cur_team_cognome,
      ruolo:this.cur_team_ruolo,
      categoria:this.cur_team_categoria,
      ente:this.cur_team_ente,
    })

    this.cur_team_nome = "";
    this.cur_team_cognome = "";
    this.cur_team_ruolo = "";
    this.cur_team_categoria = "";
    this.cur_team_ente = "";
  }

  removeTeamRow(index){
    this.appform.team.splice(index, 1);
  }

  teamClick(teamInput){
    if(this.cur_team_nome == "" || this.cur_team_cognome == "" || this.cur_team_ruolo == ""){
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Riempi i campi nome, cognome e ruolo.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    teamInput.click()
  }
  handleFileInputTeam(files: FileList, type:string) {
    var fileToUpload = files.item(0);
    type = "team";
    if(!this.checkFileType(fileToUpload.type, type)){
      // alert("file non supportato")
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"File non supportato.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    if(fileToUpload.size > 15000000) {
      // alert("file troppo grande")
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"File troppo grande. Max 15 MB",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return;
    }

    const dialogRefLoading = this.dialog.open(AlertDialogComponent, {
      width: '450px',
      data: {
        titolo:"Caricamento file in corso...",
        testo:"",
        testo2:"",
        hasCancel:false,
        hasConfirmProject:false,
        hasLoading:true
      }
    });

    this.api.postFileTeam(fileToUpload, type, this.cur_team_nome, this.cur_team_cognome, this.cur_team_ruolo).subscribe(res => {
      console.log(res);

      this.cur_team_nome = ""
      this.cur_team_cognome = ""
      this.cur_team_ruolo = ""
      dialogRefLoading.close()
      this.reloadFiles();
      // do something, if upload success
    }, error => {
      console.log(error);
      dialogRefLoading.close()
      this.reloadFiles();
    });

  }

  loader(type:string){
    if(type === "businessplan")
      this.business_loader = true
    else if(type === "team")
      this.team_loader = true
    else if(type === "altro")
      this.altro_loader = true
  }

  checkFileType(mimetype, type){
    if(type === "businessplan"){
    // .xls, .pdf, .ppt
      return (
        Consts.mimeXLS.indexOf(mimetype) > -1 ||
        Consts.mimePDF.indexOf(mimetype) > -1 ||
        Consts.mimeDOC.indexOf(mimetype) > -1
      )

    }
    else if(type === "team"){
    // .doc, .pdf, .jpg
      return (
        Consts.mimeDOC.includes(mimetype) ||
        Consts.mimePDF.includes(mimetype)
      )

    } else if(type === "altro"){
    // .doc, .ppt, .jpg, .xls, .mp3, .mp4, etc.
      return (
        Consts.mimePPT.includes(mimetype) ||
        Consts.mimeImage.includes(mimetype) ||
        Consts.mimeVideo.includes(mimetype) ||
        Consts.mimePDF.includes(mimetype)
      )


    }
    else if(type === "businessmodel"){
      // .doc, .ppt, .jpg, .xls, .mp3, .mp4, etc.
      return (
        Consts.mimePDF.includes(mimetype)
      )


    }
    return false;
  }

  removeFile(id){
    this.id_loader = id
    this.api.removeFile(id).subscribe(res => {
      console.log(res);
      this.reloadFiles();
      // do something, if upload success
    }, error => {
      console.log(error);
    });
  }

  confermaInvio() {

    if(!this.cb1 || !this.cb2 || !this.cb3 ) {
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Ricorda di selezionare le caselle di controllo delle autodichiarazioni per proseguire con la procedura.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return;

    }

    if(!this.check()){
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Riempi tutti i campi obbligatori.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return;
    }

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '450px',
      data: {
        titolo:"Conferma il progetto e genera il PDF",
        testo:"Attenzione: il progetto non potrà più essere modificato",
        hasCancel:true,
        hasConfirmProject:true,
        item:this.appform
      }
    });
  }

  check(): boolean{
    if(this.appform.prodotto == "") return false;
    // if(this.appform.idea =="")return false;
    // if(this.appform.descrizione =="")return false;
    if(this.appform.stato =="")return false;
    if(this.appform.stato =="1"){
      if(this.appform.fondazione == "") return false;
      if(this.appform.tipo_impresa == "") return false;
      if(this.appform.impresa == "") return false;
      if(this.appform.cf_azienda == "") return false;
      if(this.appform.forma_sociale == "") return false;
      if(this.appform.sede_legale_impresa_costituita == "") return false
      if(this.appform.comune_impresa_costituita == "") return false
      if(this.appform.provincia_impresa_costituita == "") return false
    }
    if(this.appform.settore =="")return false;

    // if(this.appform.concorso =="")return false;


    // if(this.appform.innovazione =="")return false;
    // if(this.appform.mercato =="")return false;
    // if(this.appform.sociale =="")return false;
    // if(this.appform.realizzabilita =="")return false;

    if(this.appform.stato_prodotto =="")return false;
    if(this.appform.trl =="")return false;
    // if(this.appform.descrizione_team =="")return false;

    if(this.appform.brevetti =="")return false;
    if(this.appform.brevetti =="1" && this.appform.specifica_brevetti == "")return false;
    if(this.appform.premi =="")return false;
    if(this.appform.premi =="1" && this.appform.specifica_premi == "")return false;

    // if(this.appform.business_model.length == 0)return false;
    if(this.appform.team.length == 0)return false;

    //referente2
    if(this.appform.ref_s_nome =="")return false;
    if(this.appform.ref_s_cognome =="")return false;
    if(this.appform.ref_s_cf =="")return false;

    if(this.appform.ref_s_tel =="")return false;
    if(this.appform.ref_s_pec =="")return false;
    if(this.appform.ref_s_email =="")return false;

    if(this.appform.documento_identita == "")return false
    // if(this.appform.presentazione == "")return false
    // if(this.appform.link_youtube == "")return false


    if(this.appform.prodotto_problema == "") return false
    if(this.appform.descrizione_prodotto == "") return false
    if(this.appform.cliente_prodotto == "") return false
    if(this.appform.modello_business_prodotto == "") return false
    if(this.appform.concorrenti_mercato == "") return false
    if(this.appform.differenzazione_concorrenti_startup == "") return false
    if(this.appform.risultati_impatto_sociale == "") return false
    if(this.appform.risultati_impatto_ambientale == "") return false

    // if(this.appform.altro.length == 0)return false;
    return true;

  }

  // function downloadURI(uri, name) {
  //   var link = document.createElement("a");
  //   link.download = name;
  //   link.href = uri;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   delete link;
  // }
  scaricaPDF(){

    this.api.downloadPDF()

    // .subscribe((resultBlob: Blob) => {
      //   var downloadURL = URL.createObjectURL(resultBlob);
      //   window.open(downloadURL);
      // });


    // this.api.downloadPDF().subscribe(res => {
    //   console.log(res);
    //
    //   // do something, if upload success
    // }, error => {
    // });
  }

  scaricaModelloPartecipazione() {
    this.api.downloadModelloPartecipazione()
  }

}
