import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {Router} from "@angular/router";
import {User} from "../model/user";
import {Consts} from "../Consts";
import {Regione} from "../model/regione";
import {Provincia} from "../model/provincia";
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.less']
})
export class AccountComponent implements OnInit {

  constructor(private api: ApiService, private router: Router, public dialog: MatDialog) { }

  user: User = <User>{}
  showLoader = false;

  regioni: Array<Regione> = Array<Regione>()
  province: Array<Provincia> = Array<Provincia>()

  ngOnInit(): void {
    var username = localStorage.getItem(Consts.USERNAME) || ""
    if(username === "")
      this.router.navigate(['/login'])

    // this.regioni = Consts.regioni
    this.api.localita().subscribe(res => {
      if (res['success']) {
        this.regioni = res['items']
      }
      this.api.me().subscribe(res => {
        if (res['success']) {
          this.user = res['me']
          var regione = this.regioni.find(el => el.id == this.user.regione)
          console.log(this.user.regione)
          console.log(this.regioni)
          this.province = regione.province
        }
      })
    })
    // console.log(Consts.regioni)

  }

  onSubmit() {


    if(
      this.user.nome === "" ||
      this.user.cognome === "" ||
      this.user.telefono === ""
    ){
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Riempi tutti i campi obbligatori.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    console.log(this.user)

    this.api.updateAccount(this.user).subscribe((res) => {
      if (res.success) {
        // this.reload();
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '450px',
          data: {
            titolo:"Account aggiornato",
            testo:"",
            testo2:"",
            hasCancel:false,
            hasConfirmProject:false
          }
        });
      }
      else{
        //messaggio di errore
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '450px',
          data: {
            titolo:"Errore",
            testo:"Errore nell'aggiornamento dell'account",
            testo2:"",
            hasCancel:false,
            hasConfirmProject:false
          }
        });
      }
    })

  }

  onSelectChangeRegione() {
    var regione = this.regioni.find(el => el.id == this.user.regione)
    this.province = regione.province
  }

}
