<div class="container bg-salmone admin-login-main">
  <div class="row">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <img style="max-width: 200px" src="./assets/logoWhite.svg" class="logo-big" alt="">
        </div>

      </div>
      <div class="row">
        <div class="col-lg-6 offset-lg-3">
          <div class="row">
            <div class="col-md-12 mt-4 mt-md-0">
              <div class="row appform">
                <div class="col-lg-12">
                  <form method="post" class="row" #f="ngForm" (ngSubmit)="onSubmit(f)">
                    <div class="col-12">
                      <div class="bg-gray-2">
                        <label>
                          Email*
                          <input type="email" ngModel name="username" class="mb-4" placeholder="Email*" required>
                        </label>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="bg-gray-2">
                        <label>
                          Password*
                          <input type="password" ngModel name="password" class="mb-4" placeholder="Password*" required>
                        </label>
                      </div>
                    </div>

                    <div class="col-12">
                      <a href="javascript:void(0)" (click)="lostPw()" class=" d-block mt-3 mb-3 hover_blu"
                         style="font-size: 13px;">Hai dimenticato la password?</a>
                    </div>

                    <div class="col-12">
                      <div class="row align-items-center justify-content-center">
                        <div class="col-auto">
                          <button *ngIf="!showLoader" type="submit" class="btn btn-cyan">Accedi</button>
                          <mat-progress-spinner color="primary" *ngIf="showLoader" diameter="30"
                                                mode="indeterminate"></mat-progress-spinner>
                        </div>

                        <div class="col-auto">
                          oppure
                        </div>

                        <div class="col-auto">
                          <a class="btn btn-cyan" routerLink="/registrazione">REGISTRATI</a>
                        </div>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
