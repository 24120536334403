import {Regione} from "./model/regione";

export class Consts {
  public static TOKEN = 'af_token'
  public static ROLE = 'af_ruolo'
  public static USERNAME = 'af_username'
  public static SENT = 'af_sent'
  public static EXPIRATION = 'af_expiration'
  public static mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  // public static baseUrl = "http://localhost:8000/"
  public static baseUrl = "https://applicationform.ms3dev.it/api_sca/api/public/"
  // public static baseUrl = "https://www.startcupabruzzo.it/applicationform/api/public/"
  // public static baseUrlSuffix = "api/public"
  public static baseUrlSuffix = ""
  public static regioni: Array<Regione> = Array<Regione>()

  // public static notAllowedEmails = ['@gmail', '@yahoo', '@libero', '@hotmail'];

    // .avi .mp4 .mpeg .ogv .webm
  public static mimeVideo = [
    "video/x-msvideo", "video/mp4", "video/mpeg", "video/ogg", "video/webm"
  ]

    // .jpeg .jpg .png
  public static mimeImage = [
    "image/jpeg", "image/png"
  ]
    // .pdf
  public static mimePDF = [
    "application/pdf"
  ]

    // .doc .docx .odt
  public static mimeDOC = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.oasis.opendocument.text"
  ]

    // .ods .xls .xlsx
  public static mimeXLS = [
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ]

    // .odp .ppt .pptx
  public static mimePPT = [
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ]
}

