import {Injectable} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {ApiService} from "./api.service";
import {Consts} from "./Consts";


@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router, private api: ApiService) {
  }

  async canActivate(): Promise<boolean> {
    return await this.api.me().toPromise().then(res => {
      if(!res['success']) {
        localStorage.removeItem(Consts.TOKEN)
        localStorage.removeItem(Consts.ROLE)
        localStorage.removeItem(Consts.USERNAME)
        this.router.navigate(['/'])
      }
      return res["success"] as boolean
    })
  }
}
