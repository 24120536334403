import { Component, OnInit } from '@angular/core';
import {ApiService} from "../api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgForm} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.less']
})
export class ResetpasswordComponent implements OnInit {
  token: string;
  success = false;
  showLoader = false;

  constructor(private api: ApiService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.token = this.route.snapshot.paramMap.get('token');
  }


  ngOnInit(): void {
    this.api.checkTokenPassword(this.token).subscribe(res => {
      this.success = res.success;
      if (this.success === false) {
        console.log(res)
        // this.router.navigate(['/']);
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '450px',
          data: {
            titolo:"Attenzione",
            testo:"Token non valido.",
            testo2:"",
            hasCancel:false,
            hasConfirmProject:false
          }
        });
        this.router.navigate(['/'])
      }
      // else{
      //
      // }
    });
  }

  onSubmit(f: NgForm) {
    if (f.valid) {
      this.api.resetPasswordToken(f.value).subscribe((res) => {
        if (res.success) {
          // location.reload();
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '450px',
            data: {
              titolo:"Pasword aggiornata",
              testo:"Inserisci una password.",
              testo2:"",
              hasCancel:false,
              hasConfirmProject:false
            }
          });
          this.router.navigate(['/'])
        }
        else{
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '450px',
            data: {
              titolo:"Attenzione",
              testo:"Errore.",
              testo2:"",
              hasCancel:false,
              hasConfirmProject:false
            }
          });
        }
      });
    }
    else{
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Inserisci una password.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
    }
  }

}
