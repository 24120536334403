<div class="container bg-salmone admin-login-main">
<div class="l-section-h i-cf">
  <h1>Privacy Policy &amp; Cookies</h1>
  <p>RIFERIMENTI NORMATIVI<br>Decreto Legislativo 30 giugno 2003, n. 196 – “Codice in materia di protezione dei dati
    personali”
    <br>Regolamento UE 2016/679 del 27 aprile 2016 relativo alla protezione delle persone fisiche con riguardo al
    trattamento dei dati personali, nonché alla libera circolazione di tali dati.</p>

  <p>IL TITOLARE DEL TRATTAMENTO<br>Il Titolare del trattamento è Associazione Innovalley con sede legale in Via Giacomo
    Matteotti 2, Atessa (CH).</p>

  <p>FINALITÀ DEL TRATTAMENTO<br>A seguito della consultazione di questo sito possono essere trattati dati relativi a
    persone identificate o identificabili.</p>

  <p><em>-Dati di navigazione-</em><br>I sistemi informatici e le procedure software preposte al funzionamento di questo
    sito web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita
    nell’uso dei protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere
    associate a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed
    associazioni
    con dati detenuti da terzi, permettere di identificare gli utenti. In questa categoria di dati rientrano gli
    indirizzi
    IP o i nomi a dominio dei computer utilizzati dagli utenti che si connettono al sito, gli indirizzi in notazione URI
    (Uniform Resource Identifier) delle risorse richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre
    la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della
    risposta data dal server (buon fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente
    informatico dell’utente. Questi dati vengono utilizzati al solo fine di ricavare informazioni statistiche anonime
    sull’uso del sito e per controllarne il corretto funzionamento e vengono cancellati periodicamente.
    I dati potrebbero essere utilizzati per l’accertamento di responsabilità in caso di ipotetici reati informatici ai
    danni del sito: salva questa eventualità, allo stato i dati sui contatti web vengono cancellati periodicamente.
    Il sito utilizza cookie tecnici e di sezione per garantire la sua funzionalità. Tali cookie non raccolgono
    informazioni
    relative alle attività di navigazione.</p>

  <p><em>-Cookies-</em>
    <br>Questo sito utilizza i “cookies”. I cookies sono piccole porzioni di dati che vengono archiviati temporaneamente
    nella memoria browser. I dati raccolti tramite i cookies permettono di confrontare i visitatori nuovi e quelli
    passati,
    capire come gli utenti navigano il sito web, ed in generale consentono una migliore esperienza di utilizzo del sito.<br>
    Se si desidera disabilitare l’uso dei cookie è necessario personalizzare le impostazioni del proprio computer
    impostando
    la cancellazione di tutti i cookie o attivando un messaggio di avviso quando i cookie vengono memorizzati.
    Per procedere senza modificare l’applicazione dei cookie è sufficiente continuare con la navigazione.
    Visita “AboutCookies.org” per ulteriori informazioni sui cookie e su come influenzano la tua esperienza di
    navigazione.</p>

  <p>I tipi di cookie che utilizziamo</p>

  <p>Cookie indispensabili<br>Questi cookie sono essenziali alla navigazione in tutto il sito ed all’utilizzo
    di tutte le sue funzionalità, come ad esempio accedere alle varie aree protette del sito. Senza questi cookie
    alcuni servizi necessari, come ad esempio la compilazione di un form non possono essere fruiti.</p>

  <p>Performance cookie<br>Questi cookie raccolgono informazioni su come gli utenti utilizzano un sito web, ad esempio
    quali sono le pagine più visitate, o se si ricevono messaggi di errore da pagine web. Questi cookie non raccolgono
    informazioni che identificano un visitatore. Tutte le informazioni raccolte tramite cookie sono aggregate e quindi
    anonime.
    Vengono utilizzati solo per migliorare il funzionamento di un sito web. Utilizzando il nostro sito,
    l’utente accetta che tali cookie possono essere memorizzati sul proprio dispositivo.</p>

  <p>Cookie di funzionalità<br>I cookie consentono al sito di ricordare le scelte fatte dall’utente
    (come il nome, la lingua o la regione di provenienza) e forniscono funzionalità avanzate personalizzate.
    Questi cookie possono essere utilizzati anche per ricordare le modifiche apportate alla dimensione del testo,
    font ed altre parti di pagine web che è possibile personalizzare. Essi possono essere utilizzati anche per
    fornire servizi che hai chiesto come guardare un video o i commenti su un blog. Le informazioni raccolte da
    questi tipi di cookie possono essere rese anonime e non in grado di monitorare la vostra attività di navigazione
    su altri siti web. Utilizzando il nostro sito, l’utente accetta che tali cookie possono essere installati sul
    proprio dispositivo.</p>

  <p>Cookie utilizzati dal presente sito</p>

  <p>Il presente sito si avvale dell’utilizzo di cookies sia di prima parte che di terza.
    <br>Nella tabella sottostante vengono riportati, nel dettaglio, i cookies attualmente presenti nel sito web.</p>

  <table class="wp-block-table has-fixed-layout is-style-stripes">
    <tbody>
    <tr>
      <th><strong>NOME COOKIE</strong></th>
      <th><strong>SCADENZA PREDEFINITA</strong></th>
      <th><strong>TIPO</strong></th>
      <th><strong>DESCRIZIONE COOKIE</strong></th>
    </tr>

    <tr>
      <td><strong>_ga</strong></td>
      <td>La scadenza di default è impostata dopo 2 anni</td>
      <td><strong>Analitico di terza parti</strong></td>
      <td>Questo cookie è associato a Google Analytics ed è utilizzato per distinguere gli utenti, assegnando a ciascuno
        di loro un numero generato in modo casuale come identificatore. Viene utilizzato per calcolare il numero di
        visitatori della pagina, delle sessioni e dei dati al fine di produrre report di analisi statistica
        anonimizzata.
      </td>
    </tr>

    <tr>
      <td><strong>_gid</strong></td>
      <td>La scadenza di default è impostata dopo 24 ore</td>
      <td><strong>Analitico di terza parti</strong></td>
      <td>Questo cookie è associato a Google Analytics ed è utilizzato per distinguere gli utenti, assegnando a ciascuno
        di loro un numero generato in modo casuale come identificatore. Viene utilizzato per calcolare il numero di
        visitatori della pagina, delle sessioni e dei dati al fine di produrre report di analisi statistica
        anonimizzata.
      </td>
    </tr>

    <tr>
      <td><strong>_utma</strong></td>
      <td>Persistente</td>
      <td><strong>Analitico di terza parti</strong></td>
      <td>Questo cookie è associato a Google Analytics ed è utilizzato per tracciare quante volte il browser ha visitato
        il sito, le date di inizio e di fine di una visita.
      </td>
    </tr>
    <tr>
      <td><strong>_utmc</strong></td>
      <td>La scadenza di default è impostata al termine della sessione</td>
      <td><strong>Analitico di terza parti</strong></td>
      <td>Questo cookie è associato a Google Analytics ed è utilizzato per tracciare la durata della visita.</td>
    </tr>
    <tr>
      <td><strong>_utmz</strong></td>
      <td>La scadenza di default è impostata dopo 6 mesi</td>
      <td><strong>Analitico di terza parti</strong></td>
      <td>Questo cookie è associato a Google Analytics ed è utilizzato per tracciare la provenienza del visitatore, i
        search engine utilizzati, il tipo di browser, i link cliccati.
      </td>
    </tr>
    </tbody>
  </table>


  <p><em>-Dati forniti volontariamente dall’utente-</em><br>L’invio facoltativo, esplicito e volontario di dati
    personali da parte dell’utente nei form di registrazione / raccolta dati personali presenti su questo sito comporta
    la successiva acquisizione dei dati forniti dal mittente, necessari per l’erogazione del servizio richiesto.
    Specifiche informative verranno progressivamente riportate o visualizzate nelle pagine del sito predisposte per
    particolari servizi a richiesta.</p>


  <p>FACOLTATIVITA’ DEL CONFERIMENTO DEI DATI<br>A parte quanto specificato per i dati di navigazione, l’utente è libero
    di fornire i dati personali riportati nei moduli di richiesta o comunque indicati in contatti per sollecitare
    l’invio di materiale informativo o di altre comunicazioni. Il loro mancato conferimento può comportare
    l’impossibilità di ottenere quanto richiesto.</p>


  <p>Normalmente i browser permettono il controllo della maggior parte dei cookie tramite le impostazioni del browser
    stesso. Si ricorda tuttavia che la disabilitazione totale o parziale dei cookies c.d. tecnici può compromettere
    l’utilizzo delle funzionalità del sito. Ad ogni modo qualora l’utente non desideri ricevere alcun tipo di cookies
    sul proprio elaboratore, né da questo sito, né da altri, può elevare il livello di protezione privacy modificando le
    impostazioni di sicurezza del proprio browser:<br>Chrome:&nbsp;<a
      href="https://support.google.com/chrome/answer/95647?hl=it">https://support.google.com/chrome/answer/95647?hl=it</a><br>Firefox:&nbsp;<a
      href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a><br>Internet
    Explorer:&nbsp;<a href="http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9">http://windows.microsoft.com/it-it/windows7/how-to-manage-cookies-in-internet-explorer-9</a><br>Opera:&nbsp;<a
      href="http://help.opera.com/Windows/10.00/it/cookies.html">http://help.opera.com/Windows/10.00/it/cookies.html</a><br>Safari:&nbsp;<a
      href="http://support.apple.com/kb/HT1677?viewlocale=it_IT">http://support.apple.com/kb/HT1677?viewlocale=it_IT</a>
  </p>


  <p>Si ricorda che è possibile disabilitare in modo selettivo l’azione di Google Analytics installando sul proprio
    browser la componente di opt-out fornita da Google. Per disabilitare l’azione di Google Analytics, si rinvia al link
    di seguito indicato: https://tools.google.com/dlpage/gaoptout.</p>


  <p>COMUNICAZIONE DEI DATI PERSONALI<br>I dati personali raccolti potranno essere comunicati a soggetti terzi
    autorizzati per attività strettamente connesse e strumentali all’operatività del servizio, come la gestione del
    sistema informatico, o per trattamenti effettuati dalle altre società con le medesime finalità. Al di fuori di
    questi casi, i dati personali non saranno comunicati, né concessi ad alcuno, salvo previsione contrattuale o
    autorizzazione dei soggetti. In questo senso, i dati personali potrebbero essere trasmessi a terze parti, ma solo ed
    esclusivamente nel caso in cui:</p>


  <ul>
    <li>vi sia consenso esplicito a condividere i dati con terze parti;</li>
    <li>vi sia la necessità di condividere con terzi le informazioni al fine di prestare il servizio richiesto;</li>
    <li>ciò sia necessario per adempiere a richieste dell’Autorità Giudiziaria o di Pubblica Sicurezza.</li>
  </ul>


  <p>LUOGO DI TRATTAMENTO DEI DATI<br>I trattamenti connessi ai servizi web di questo sito hanno luogo presso uno spazio
    su server di Aruba S.p.A. – Via San Clemente, 53 - 24036 Ponte San Pietro (BG)
  </p>


  <p>LINK AD ALTRI SITI WEB<br>Questo sito potrebbe contenere link o riferimenti per l’accesso ad altri siti Web. La
    Società non controlla le tecnologie di monitoraggio di altri siti Web ai quali la presente Policy non si applica.
  </p>


  <p>TEMPO DI CONSERVAZIONE<br>I dati personali sono trattati con strumenti automatizzati per il tempo strettamente
    necessario a conseguire gli scopi per cui sono stati raccolti. I dati personali raccolti per finalità di analisi
    statistiche sono anonimizzati.</p>


  <p>DIRITTI DEGLI INTERESSATI<br>I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento
    di ottenere la conferma dell’esistenza o meno dei medesimi dati e di conoscerne il contenuto e l’origine,
    verificarne l’esattezza o chiederne l’integrazione o l’aggiornamento, oppure la rettificazione (art. 7 del Decreto
    legislativo 30 giugno 2003, n. 196, nonché articoli da 15 a 22 del Regolamento UE 2016/679). Ai sensi del medesimo
    articolo 7 del Decreto legislativo 30 giugno 2003, numero 196 e degli articoli 15 e seguenti del Regolamento UE
    2016/679 si ha il diritto di chiedere la cancellazione, la trasformazione in forma anonima o il blocco dei dati
    trattati in violazione di legge, nonché di opporsi in ogni caso, per motivi legittimi, al loro trattamento.</p>


  <p>MODIFICHE ALLE PRESENTI POLITICHE SULLA PRIVACY<br>Associazione Innovalley verifica periodicamente le proprie
    politiche relative alla privacy e alla sicurezza e, se del caso, le rivede in relazione alle modifiche normative,
    organizzative o dettate dall’evoluzione tecnologica. In caso di modifica delle politiche, la nuova versione sarà
    pubblicata in questa pagina del sito.</p>


  <p>DOMANDE, RECLAMI E SUGGERIMENTI<br>Chiunque fosse interessato a maggiori informazioni, a contribuire con propri
    suggerimenti o avanzare reclami o contestazioni in merito alle politiche privacy della società o sul modo in cui
    Associazione Innovalley tratta i dati personali, può effettuarlo scrivendo al seguente indirizzo e-mail:
    privacy@innovalley.it.</p>
</div>
</div>
