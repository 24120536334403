import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Login} from "./model/login";
import {Consts} from "./Consts";
import {Appform} from "./model/appform";
import {Observable} from "rxjs";
import {User} from "./model/user";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl = Consts.baseUrl + Consts.baseUrlSuffix

  constructor(private http: HttpClient) {
  }

  me() {
    return this.http.get(this.baseUrl + '/me')
  }

  login(obj: { username: string, password: string }) {
    return this.http.post<{ success:boolean, message:string, user:User, token: string }>(this.baseUrl + '/login', {username: obj.username, password: obj.password})
  }

  lostPassword(obj) {
    return this.http.post(this.baseUrl + '/lost_password', {
      email: obj.email
    });
  }

  checkTokenPassword(token): Observable<{ success: boolean }> {
    return this.http.get<{ success: boolean }>(this.baseUrl + '/check_token/' + token);
  }

  resetPasswordToken(obj: { token: string, password: string }) {
    return this.http.post<{ success: boolean }>(this.baseUrl + '/reset_password_token', obj);
  }

  registration(obj: { nome: string,
    cognome: string,
    telefono: string,
    email: string,
    password: string,
    regione: string,
    provincia: string,
    indirizzo: string,
    pec: string,
    cf: string,
    data_nascita: string,
    nato_a: string,
    provincia_nascita: string,
    citta_residenza: string,
    provincia_residenza: string,
    via_residenza: string,
    numero_residenza: string,
    cap_residenza: string,
    citta_domicilio: string,
    provincia_domicilio: string,
    via_domicilio: string,
    numero_domicilio: string,
    cap_domicilio: string

  }) {
    return this.http.post<Login>(this.baseUrl + '/registration', {
      nome: obj.nome,
      cognome: obj.cognome,
      telefono: obj.telefono,
      email: obj.email,
      password: obj.password,
      regione: obj.regione,
      provincia: obj.provincia,
      indirizzo: obj.indirizzo,
      pec: obj.pec,
      cf: obj.cf,
      privacy: 1,
      data_nascita: obj.data_nascita,
      nato_a: obj.nato_a,
      provincia_nascita: obj.provincia_nascita,
      citta_residenza: obj.citta_residenza,
      provincia_residenza: obj.provincia_residenza,
      via_residenza: obj.via_residenza,
      numero_residenza: obj.numero_residenza,
      cap_residenza: obj.cap_residenza,
      citta_domicilio: obj.citta_domicilio == null ? '' : obj.citta_domicilio,
      provincia_domicilio: obj.provincia_domicilio == null ? '' : obj.provincia_domicilio,
      via_domicilio: obj.via_domicilio == null ? '' : obj.via_domicilio,
      numero_domicilio: obj.numero_domicilio == null ? '' : obj.numero_domicilio,
      cap_domicilio: obj.cap_domicilio == null ? '' : obj.cap_domicilio
    })
  }

  updateAccount(user){
    return this.http.put<Login>(this.baseUrl + '/update_account', user)
  }

  // salva la form
  inviaForm(appform) {
    return this.http.post<Login>(this.baseUrl + '/send', appform)
  }

  // get regioni e province
  localita(){
    return this.http.get(this.baseUrl + '/localita');
  }

  // get current form
  getCurrentForn(){
    return this.http.get(this.baseUrl + '/getform');
  }

  // convalida la form
  // dopo la convalida non si potrà più modificare il form
  convalida(appform){
    return this.http.put<Login>(this.baseUrl + '/confirm', appform)
  }

  // fileUpload(){
  //
  // }

  postFile(fileToUpload: File, type:string) {

    var formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('type', type);
    return this.http.post(this.baseUrl + '/file_upload', formData);

  }

  postFileTeam(fileToUpload: File, type:string, nome:string, cognome:string, ruolo:string) {

    var formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    formData.append('type', type);
    formData.append('nome', nome);
    formData.append('cognome', cognome);
    formData.append('ruolo', ruolo);
    return this.http.post(this.baseUrl + '/file_upload', formData);

  }

  removeFile(id){
    return this.http.delete(this.baseUrl + '/remove_file/'+id);
  }

  downloadModelloPartecipazione() {
    this.http.get(this.baseUrl + '/modello_partecipazione', {
      responseType: 'arraybuffer'
    }).subscribe(res => {
      const blob = new Blob([res], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url)
    });
  }

  downloadPDF(){
    this.http.get(this.baseUrl + '/getformpdf', {
      responseType: 'arraybuffer'
    }).subscribe(res => {
      const blob = new Blob([res], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url)
      });



    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/pdf',
    //     responseType : 'blob',
    //     Accept : 'application/pdf',
    //     observe : 'response'
    //   })
    // };
    // return this.http.get(this.baseUrl + '/getformpdf', httpOptions);
  }

}
