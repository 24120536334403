<div class="container-fluid bg-salmone admin-login-main">
  <div class="row">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 style="font-weight: bold" class="color-white mb-5">Registrazione Utente</h1>
        </div>

      </div>
      <div class="row">

        <div class="col-auto mb-1 mt-2" *ngIf="iscrizioni_chiuse">
          <div class="bg-gray-2">
            <h3 class="msg_chiusura">Le iscrizioni sono chiuse</h3>
            <h3 class="msg_chiusura">Grazie per aver partecipato</h3>
          </div>
        </div>

        <div class="col-md-12 mt-4 mt-md-0">
          <div class="row">
            <div class="col-12 ">
              <form method="post" #f="ngForm" (ngSubmit)="onSubmit(f)">

                <div class="row appform">
                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Nome*
                        <input type="text" placeholder="Nome" ngModel name="nome" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Cognome*
                        <input type="text" placeholder="Cognome" ngModel name="cognome" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Data di nascita*
                        <input type="date" placeholder="Data di nascita" ngModel name="data_nascita" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Nato a*
                        <input type="text" placeholder="Nato a" ngModel name="nato_a" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="bg-gray-2">
                      <label>
                        Provincia*
                        <input type="text" placeholder="Provincia" ngModel name="provincia_nascita" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Città di residenza*
                        <input type="text" placeholder="Città di residenza" ngModel name="citta_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Provincia di residenza*
                        <input type="text" placeholder="Provincia di residenza" ngModel name="provincia_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Via di residenza*
                        <input type="text" placeholder="Via di residenza" ngModel name="via_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Numero di residenza*
                        <input type="text" placeholder="Numero di residenza" ngModel name="numero_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Cap di residenza*
                        <input type="text" placeholder="Cap di residenza" ngModel name="cap_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-12 margin_line" style="border: 1px solid white;"></div>

                  <div class="col-12 mb-3">
                    <p style="font-size: 20px">Inserisci il domicilio o recapito per comunicazioni, se è diverso dalla residenza:</p>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Città di domicilio
                        <input type="text" placeholder="Città di domicilio" ngModel name="citta_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Provincia di domicilio
                        <input type="text" placeholder="Provincia di domicilio" ngModel name="provincia_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Via di domicilio
                        <input type="text" placeholder="Via di domicilio" ngModel name="via_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Numero di domicilio
                        <input type="text" placeholder="Numero di domicilio" ngModel name="numero_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Cap di domicilio
                        <input type="text" placeholder="Cap di domicilio" ngModel name="cap_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-12 margin_line" style="border: 1px solid white;"></div>


                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Codice fiscale*
                        <input type="text" placeholder="Codice fiscale" ngModel name="cf" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Telefono*
                        <input type="text" placeholder="Telefono" ngModel name="telefono" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Email*
                        <input type="email" ngModel placeholder="Email" name="email" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        PEC*
                        <input type="email" ngModel placeholder="PEC" name="pec" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Password*
                        <input type="password" ngModel placeholder="Password" name="password" class="mb-4" required>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Ripeti Password*
                        <input type="password" ngModel placeholder="Ripeti Password" name="repassword" class="mb-4" required>
                      </label>
                    </div>
                  </div>
<!--                  <div class="col-md-6"></div>-->
                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Regione*
                        <select ngModel class="mb-4" name="regione" (change)="onSelectChange($event.target.value)"
                                required>
                          <option selected value="">Seleziona una regione</option>
                          <option value="-1">Estero</option>
                          <option *ngFor="let i of regioni" value="{{i.id}}">{{i.nome}}</option>
                        </select>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6" *ngIf="f.value.regione > 0">
                    <div class="bg-gray-2">
                      <label>
                        Provincia
                        <select ngModel class="mb-4" name="provincia">
                          <option selected value="">Seleziona una provincia</option>
                          <option *ngFor="let i of province; let i_ = index" [selected]="i_==0"
                                  value="{{i.id}}">{{i.nome}}</option>
                        </select>
                      </label>
                    </div>
                  </div>


                  <div class="col-12 hidden">
                    <div class="bg-gray-2">
                      <label>
                        Indirizzo*
                        <input ngModel type="text" name="indirizzo" class="mb-4" >
                      </label>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="bg-gray-2 row-privacy">

                      <input name="privacy" type="checkbox" id="privacy-1" value="1" ngModel>
                      <label for="privacy-1">*Dichiaro di aver preso visione dell’Informativa Privacy disponibile sul
                        sito
                        alla pagina <a target="_blank" href="/privacy" class="hover_blu">privacy</a>.
                        Ai sensi del Considerando 32 e dell’art. 7 GDPR del Regolamento UE 2016/679, presto esplicito
                        consenso
                        al trattamento dei dati personali forniti, raccolti ed utilizzati secondo le procedure,
                        per le finalità e per il periodo necessario alla gestione del presente evento.</label>
                    </div>
                  </div>

                  <div class="col-12 align-center" *ngIf="!showLoader && !iscrizioni_chiuse">
                    <button type="submit" class="btn btn-cyan">Registrati</button>
                  </div>

                  <div class="col-12 align-center" *ngIf="showLoader">
                    <mat-progress-spinner diameter="30" mode="indeterminate"></mat-progress-spinner>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
