import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {Router} from "@angular/router";
import {ApiService} from "../api.service";
import {Consts} from "../Consts";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  @ViewChild('f') f: NgForm;
  showLoader = false;

  constructor(private api: ApiService, private router: Router, public dialog: MatDialog) {}

  ngOnInit(): void {
    var username = localStorage.getItem(Consts.USERNAME) || ""
    if(username !== "")
      this.router.navigate(['/account'])
  }

  onSubmit(f: NgForm) {

    if (f.valid) {

      if(!this.isValidMail(this.f.value.username)){
        // alert("Formato email non valido.")
        // return
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '450px',
          data: {
            titolo:"Attenzione",
            testo:"Formato email non valido.",
            testo2:"",
            hasCancel:false,
            hasConfirmProject:false
          }
        });
        return
      }
      this.showLoader = true
      this.api.login(f.value).subscribe((res) => {
        if (res.success && res.token) {
          localStorage.setItem(Consts.TOKEN, res.token)
          this.api.me().subscribe(res => {
            if (res['success']) {
              console.log(res)
              localStorage.setItem(Consts.USERNAME, res['me']['email'])
              localStorage.setItem(Consts.EXPIRATION, res['me']['expiration'])
              localStorage.setItem(Consts.ROLE, res['me']['role'])
              // location.reload()
              this.router.navigate(['/appform']).then(() => {
                window.location.reload();
              });
            }
            this.showLoader = false
          })
        }
        else {
          this.showLoader = false
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '450px',
            data: {
              titolo:"Attenzione",
              testo:"Login e password non validi.",
              testo2:"",
              hasCancel:false,
              hasConfirmProject:false
            }
          });
        }
      })
    }
    else{
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Inserisci email e password.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
    }
      // alert("Inserisci email e password.")

  }

  lostPw() {
    if (this.f.value.username === "") {
      // alert("Inserisci un nome utente per cui recuperare la password.")
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Inserisci l'email usata per la registrazione.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });

    }
    else  if(!this.isValidMail(this.f.value.username)){
      // alert("Formato email non valido.")
      // return
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Formato email non valido.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }
    else {
      this.api.lostPassword({email: this.f.value.username}).subscribe(res => {
        console.log(res)
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: '450px',
          data: {
            titolo:"Reset password",
            testo:"Hai richiesto il reset della password.",
            testo2:"Ti abbiamo inviato una email con il link per cambiare la password",
            hasCancel:false,
            hasConfirmProject:false
          }
        });
      })
    }
  }

  isValidMail(mail){
    return Consts.mailformat.test(mail)

  }
}
