<div class="container-fluid bg-salmone admin-login-main">
  <div class="row">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 style="font-weight: bold" class="color-white mb-3">Application form - StartCup Abruzzo 2023</h1>
        </div>

      </div>
      <div class="row appform">
        <div class="col-md-12 mt-4 mt-md-0">
          <div class="row">

            <div class="col-auto mb-1 mt-2">
              <div class="bg-gray-2">
                <h4 class="" style="font-size: 18px"> Compila tutti i campi facendo attenzione a quelli obbligatori.
                  Potrai salvare il progetto in qualsiasi momento
                  e riprendere la compilazione dello stesso successivamente. Ricorda, una volta che confermi l'invio del
                  progetto tramite apposito pulsante,
                  non sarà più possibile apportare modifiche. In bocca al lupo!</h4>

              </div>
            </div>


            <div class="col-auto mb-1 mt-2" *ngIf="iscrizioni_chiuse">
              <div class="bg-gray-2">
                <h3 class="msg_chiusura">Le iscrizioni sono chiuse</h3>
                <h3 class="msg_chiusura">Grazie per aver partecipato</h3>
              </div>
            </div>

            <div class="col-12 mb-4">
              <div class="row">
                <div class="col-auto mb-1" *ngIf="isEnabled()">
                  <div class="bg-gray-2">
                    <button type="button" (click)="onSubmit()" class="btn  btn-cyan">Salva</button>
                  </div>
                </div>

                <div class="col-auto mb-1" *ngIf="isEnabled()">
                  <div class="bg-gray-2">
                    <button type="button" (click)="confermaInvio()" class="btn btn-cyan">Conferma l'invio del progetto
                    </button>
                  </div>
                </div>

                <div class="col-auto mb-1 d-flex align-items-center" *ngIf="!isEnabled()">
                  <div class="bg-gray-2">
                    <button href="" (click)="scaricaPDF()" class="btn btn-cyan" style="font-weight: bold">Scarica il PDF
                    </button>
                    <a href=""></a>
                  </div>
                  <div class="ml-5" *ngIf="loader_pdf">
                    <mat-spinner class="spinner" [diameter]="40"></mat-spinner>
                  </div>
                </div>

              </div>
            </div>

            <div class="col-12" *ngIf="!isEnabled()">
              <div class="col-12 mt-2 mb-1 px-0">
                <div class="bg-gray-2">
                  <p style="font-size: 20px;">

                    Scarica la domanda di partecipazione, compilala in ogni sua parte e inviala all'indirizzo di posta
                    elettronica cciaa@pec.chpe.camcom.it insieme ad un documento di identità ed al pdf generato dal
                    sistema (Application Form). <br>
                    Tutti i documenti devono essere firmati digitalmente

                  </p>
                </div>
              </div>

              <div class="col-12 mt-4">
                <div class="bg-gray-2 mb-1">
                  <div class="row">
                    <h2 class="col-lg-8 px-0" style="font-size: 32px !important;">Domanda di partecipazione*</h2>
                    <div class="col-lg-4">
                      <!--                    <a class="btn  btn-cyan" href="/assets/Domanda_di_ammissione_StartcupAbruzzo2023.docx"-->
                      <!--                       target="_blank">Scarica il modello</a>-->
                      <button href="" (click)="scaricaModelloPartecipazione()" class="btn btn-cyan" style="font-weight: bold">Scarica il modello</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 margin_line" style="border: 1px solid white;"></div>
            </div>


            <div class="col-12 mb-1">
              <div class="bg-gray-2">
                <p class="titolo">
                  Indicare un secondo referente per il progetto

                </p>
              </div>
            </div>


            <div class="col-6 mb-1">
              <div class="bg-gray-2">
                <label>
                  Nome Referente*
                  <input [(ngModel)]="appform.ref_s_nome" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Nome Referente">
                </label>
              </div>
            </div>

            <div class="col-6 mb-1">
              <div class="bg-gray-2">
                <label>
                  Cognome Referente*
                  <input [(ngModel)]="appform.ref_s_cognome" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Cognome Referente">
                </label>
              </div>
            </div>

            <div class="col-6 mb-1">
              <div class="bg-gray-2">
                <label>
                  Codice fiscale*
                  <input [(ngModel)]="appform.ref_s_cf" [disabled]="isEnabled() ? null: 'readOnly'" type="text" required
                         placeholder="Codice fiscale">
                </label>
              </div>
            </div>

            <div class="col-6 mb-1">
              <div class="bg-gray-2">
                <label>
                  Telefono*
                  <input [(ngModel)]="appform.ref_s_tel" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Telefono">
                </label>
              </div>
            </div>

            <div class="col-6 mb-1">
              <div class="bg-gray-2">
                <label>
                  Email*
                  <input [(ngModel)]="appform.ref_s_email" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Email">
                </label>
              </div>
            </div>

            <div class="col-6 mb-1">
              <div class="bg-gray-2">
                <label>
                  PEC
                  <input [(ngModel)]="appform.ref_s_pec" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="PEC">
                </label>
              </div>
            </div>

            <div class="col-12 margin_line" style="border: 1px solid white;"></div>

            <div class="col-12">
              <p class="titolo">Specifiche preliminari di progetto</p>
            </div>

            <div class="col-12 mb-1">
              <div class="bg-gray-2">
                <label>
                  Nome Progetto
                  <input [(ngModel)]="appform.prodotto" [disabled]="isEnabled() ? null: 'readOnly'" type="text" required
                         placeholder="Nome Progetto / Prodotto">
                </label>
              </div>
            </div>

            <div class="col-md-6 mb-1">
              <div class="bg-gray-2">
                <label>
                  Stato prodotto/servizio*
                  <select [(ngModel)]="appform.stato_prodotto" [disabled]="isEnabled() ? null: 'readOnly'">
                    <option value="" disabled>Seleziona un opzione</option>
                    <option value="1">Idea</option>
                    <option value="2">Prototipo</option>
                    <option value="3">Sul mercato</option>
                    <option value="4">Primi clienti</option>
                  </select>
                </label>
              </div>
            </div>

            <div class="col-md-6 ">
              <div class="bg-gray-2">
                <label>
                  TRL: Technology Readiness Level*
                  <select [(ngModel)]="appform.trl" [disabled]="isEnabled() ? null: 'readOnly'">
                    <option value="" disabled>Seleziona un opzione</option>
                    <option value="1">TRL1 (Osservati i principi fondamentali)</option>
                    <option value="2">TRL2 (Formulato il concetto della tecnologia)</option>
                    <option value="3">TRL3 (Prova di concetto sperimentale)</option>
                    <option value="4">TRL4 (Tecnologia convalidata in laboratorio)</option>
                    <option value="5">TRL5 (Tecnologia convalidata in ambiente (industrialmente) rilevante)</option>
                    <option value="6">TRL6 (Tecnologia dimostrata in ambiente (industrialmente) rilevante)</option>
                    <option value="7">TRL7 (Dimostrazione di un prototipo di sistema in ambiente operativo)</option>
                    <option value="8">TRL8 (Sistema completo e qualificato)</option>
                    <option value="9">TRL9 (Sistema reale provato in ambiente operativo (produzione competitiva,
                      commercializzazione)
                    </option>
                  </select>
                </label>
              </div>
            </div>

            <!--            <div class="col-md-6 mb-1">-->
            <!--              <div class="bg-gray-2">-->
            <!--                <label>-->
            <!--                  Premio al quale si concorre*-->
            <!--                  <select [(ngModel)]="appform.concorso" [disabled]="isEnabled() ? null: 'readOnly'">-->
            <!--                    <option value="" selected disabled>Seleziona un opzione</option>-->
            <!--                    <option value="1" >Start Cup Abruzzo 2023</option>-->
            <!--&lt;!&ndash;                    <option value="2">Visionaria 2023</option>&ndash;&gt;-->
            <!--&lt;!&ndash;                    <option value="3">Start Cup Abruzzo 2023 e Visionaria 2023</option>&ndash;&gt;-->

            <!--                  </select>-->
            <!--                </label>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="col-12 mb-1">
              <div class="bg-gray-2">
                <label>
                  Settore di riferimento del progetto d’impresa*
                  <select [(ngModel)]="appform.settore" [disabled]="isEnabled() ? null: 'readOnly'">
                    <option value="" disabled>Seleziona un opzione</option>
                    <option value="1">Life Science-MEDTech</option>
                    <option value="2">ICT</option>
                    <option value="3">Clean Tech & Energy</option>
                    <option value="4">Industrial</option>
                    <option *ngIf="appform.concorso === '2'" value="5">Altro (specificare)</option>
                  </select>
                </label>
              </div>
            </div>

            <div class="col-12 mb-1" *ngIf="appform.concorso === '2' && appform.settore === '5'">
              <div class="bg-gray-2">
                <label>
                  Specificare un settore
                  <input [(ngModel)]="appform.categoria_specifica" [disabled]="isEnabled() ? null: 'readOnly'"
                         type="text"
                         required placeholder="Specificare un settore">
                </label>
              </div>
            </div>

            <div class="col-md-6 mb-1">
              <div class="bg-gray-2">
                <label>
                  Impresa costituita*
                  <select [(ngModel)]="appform.stato" [disabled]="isEnabled() ? null: 'readOnly'">
                    <option value="" disabled>Seleziona un opzione</option>
                    <option value="1">Si</option>
                    <option value="2">No</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="col-md-6 mb-1" *ngIf="appform.stato === '1'">
              <div class="bg-gray-2">
                <label>
                  Tipo Impresa costituita*
                  <select [(ngModel)]="appform.tipo_impresa" [disabled]="isEnabled() ? null: 'readOnly'">
                    <option value="" disabled>Seleziona un opzione</option>
                    <option value="1">Start up innovativa</option>
                    <option value="2">PMI innovativa</option>
                    <option value="3">Altro</option>

                  </select>
                </label>
              </div>
            </div>

            <div class="col-md-6 mb-1" *ngIf="appform.stato === '1'">
              <div class="bg-gray-2">
                <label>
                  Ragione sociale*
                  <input [(ngModel)]="appform.impresa" [disabled]="isEnabled() ? null: 'readOnly'" type="text" required
                         placeholder="Nome Impresa">
                </label>
              </div>
            </div>

            <div class="col-md-6 mb-1" *ngIf="appform.stato === '1'">
              <div class="bg-gray-2">
                <label>
                  Data di fondazione*
                  <input [(ngModel)]="appform.fondazione" type="date" [disabled]="isEnabled() ? null: 'readOnly'">
                </label>
              </div>
            </div>


            <div class="col-md-6 mb-1" *ngIf="appform.stato === '1'">
              <div class="bg-gray-2">
                <label>
                  Codice fiscale impresa*
                  <input [(ngModel)]="appform.cf_azienda" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Codice fiscale">
                </label>
              </div>
            </div>

            <div class="col-md-6 mb-1" *ngIf="appform.stato === '1'">
              <div class="bg-gray-2">
                <label>
                  Forma sociale*
                  <input [(ngModel)]="appform.forma_sociale" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Srl, Srls, Spa, Snc etc">
                </label>
              </div>
            </div>

            <div class="col-md-6 mb-1" *ngIf="appform.stato === '1'">
              <div class="bg-gray-2">
                <label>
                  Sede legale*
                  <input [(ngModel)]="appform.sede_legale_impresa_costituita" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Sede legale">
                </label>
              </div>
            </div>

            <div class="col-md-4 mb-1" *ngIf="appform.stato === '1'">
              <div class="bg-gray-2">
                <label>
                  Comune*
                  <input [(ngModel)]="appform.comune_impresa_costituita" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Comune">
                </label>
              </div>
            </div>

            <div class="col-md-2 mb-1" *ngIf="appform.stato === '1'">
              <div class="bg-gray-2">
                <label>
                  Provincia*
                  <input [(ngModel)]="appform.provincia_impresa_costituita" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Provincia">
                </label>
              </div>
            </div>

            <div class="col-md-12"></div>

            <div class="col-md-12 mb-1">
              <div class="bg-gray-2">
                <label>
                  Il progetto è stato già presentato in passato in altre edizioni di Start Cup Abruzzo?*
                  <select [(ngModel)]="appform.partecipazione" [disabled]="isEnabled() ? null: 'readOnly'">
                    <option value="" disabled>Seleziona un opzione</option>
                    <option value="1">Si</option>
                    <option value="2">No</option>
                  </select>
                </label>
              </div>
            </div>

            <div class="col-md-12 mb-1" *ngIf="appform.partecipazione === '1'">
              <div class="bg-gray-2">
                <label>
                  Specifica le partecipazioni*
                  <input [(ngModel)]="appform.partecipazione_specifica" [disabled]="isEnabled() ? null: 'readOnly'"
                         type="text" required placeholder="Specifica le partecipazioni">
                </label>
              </div>
            </div>

            <div class="col-12 margin_line" style="border: 1px solid white;"></div>

            <div class="col-12">
              <p class="titolo">Il tuo prodotto/servizio</p>
            </div>


            <div class="col-12 mb-1">
              <div class="row mt-2">
                <div class="col-8">Qual è il problema che la tua startup vuole risolvere?*</div>
                <div class="col-4 align-end {{appform.prodotto_problema.length > 300 ? 'overlimit' : ''}}">{{appform.prodotto_problema.length}} /
                  300
                </div>
              </div>
              <textarea maxlength="300" required [(ngModel)]="appform.prodotto_problema"
                        placeholder="Descrivi la tua idea in un tweet"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <div class="col-12 mb-1">
              <div class="row mt-2">
                <div class="col-8">Descrivi il tuo prodotto/servizio*</div>
                <div class="col-4 align-end {{appform.descrizione_prodotto.length > 1500 ? 'overlimit' : ''}}">{{appform.descrizione_prodotto.length}} /
                  1500
                </div>
              </div>
              <textarea maxlength="1500"  rows="5" required [(ngModel)]="appform.descrizione_prodotto"
                        placeholder="Descrivere gli elementi di originalità e di innovatività del progetto imprenditoriale rispetto allo stato dell’arte"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <div class="col-12 mb-1">
              <div class="row mt-2">
                <div class="col-8">Chi è il tuo cliente?*</div>
                <div class="col-4 align-end {{appform.cliente_prodotto.length > 1500 ? 'overlimit' : ''}}">{{appform.cliente_prodotto.length}} /
                  1500
                </div>
              </div>
              <textarea maxlength="1500"  rows="5" required [(ngModel)]="appform.cliente_prodotto"
                        placeholder="Descrivi il target/i target di riferimento a cui è destinato il tuo prodotto"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <div class="col-12 mb-5">
              <div class="row mt-2">
                <div class="col-8">Descrivi il tuo modello business*</div>
                <div class="col-4 align-end {{appform.modello_business_prodotto.length > 1500 ? 'overlimit' : ''}}">{{appform.modello_business_prodotto.length}} /
                  1500
                </div>
              </div>
              <textarea maxlength="1500"  rows="5" required [(ngModel)]="appform.modello_business_prodotto"
                        placeholder="Descrivi brevemente il metodo con cui la tua idea/prodotto può generare entrate e quindi valore"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <!--            <div class="col-12 mb-1">-->
            <!--              <div class="row mt-2">-->
            <!--                <div class="col-8">L'idea*</div>-->
            <!--                <div class="col-4 align-end {{appform.idea.length > 150 ? 'overlimit' : ''}}">{{appform.idea.length}} /-->
            <!--                  300-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <textarea [(ngModel)]="appform.idea" maxlength="300" required placeholder="Descrivi la tua idea in un tweet"-->
            <!--                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>-->
            <!--            </div>-->


            <!--            <div class="col-12 mb-1">-->
            <!--              <div class="row mt-2">-->
            <!--                <div class="col-8">L'innovazione*</div>-->
            <!--                <div-->
            <!--                  class="col-4 align-end {{appform.innovazione.length > 1500 ? 'overlimit' : ''}}">{{appform.innovazione.length}}-->
            <!--                  / 1500-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <textarea [(ngModel)]="appform.innovazione" rows="5" maxlength="1500" required-->
            <!--                        placeholder="Descrivere gli elementi di originalità e di innovatività del progetto imprenditoriale rispetto allo stato dell’arte ."-->
            <!--                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>-->
            <!--            </div>-->

            <!--            &lt;!&ndash;                max 3000&ndash;&gt;-->
            <!--            <div class="col-12 mb-1">-->
            <!--              <div class="row mt-2">-->
            <!--                <div class="col-8">Sostenibilità ambientale*</div>-->
            <!--                <div-->
            <!--                  class="col-4 align-end {{appform.mercato.length > 1500 ? 'overlimit' : ''}}">{{appform.mercato.length}}-->
            <!--                  / 1500-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <textarea [(ngModel)]="appform.mercato" rows="5" maxlength="1500" required placeholder="Descrivere le caratteristiche distintive ed innovative del progetto imprenditoriale in termini di sostenibilità ambientale"-->
            <!--                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>-->
            <!--            </div>-->

            <!--            <div class="col-12 mb-1">-->
            <!--              <div class="row mt-2">-->
            <!--                <div class="col-8">Sostenibilità sociale*</div>-->
            <!--                <div-->
            <!--                  class="col-4 align-end {{appform.sociale.length > 1500 ? 'overlimit' : ''}}">{{appform.sociale.length}}-->
            <!--                  / 1500-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <textarea [(ngModel)]="appform.sociale" rows="5" maxlength="1500" required placeholder="Descrivere le caratteristiche distintive ed innovative del progetto imprenditoriale in termini di sostenibilità sociale"-->
            <!--                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>-->
            <!--            </div>-->

            <div class="col-md-6 mb-1">
              <div class="row">
                <div class="col-md-12">
                  <div class="bg-gray-2">
                    <label>
                      Titoli di proprietà intellettuale*
                      <select [(ngModel)]="appform.brevetti" [disabled]="isEnabled() ? null: 'readOnly'">
                        <option value="" disabled>Seleziona un opzione</option>
                        <option value="1">Si</option>
                        <option value="2">No</option>
                      </select>
                    </label>
                  </div>

                </div>

                <div class="col-12" *ngIf="appform.brevetti === '1'">
                  <div class="mt-2">Titoli di proprietà intellettuale: indicare quali*</div>
                  <textarea rows="5" [(ngModel)]="appform.specifica_brevetti" required
                            placeholder="Titoli di proprietà intellettuale: indicare quali"
                            [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
                </div>

              </div>
            </div>

            <div class="col-md-6 mb-1">
              <div class="row">
                <div class="col-md-12">
                  <div class="bg-gray-2">
                    <label>
                      Riconoscimenti e premi*
                      <select [(ngModel)]="appform.premi" [disabled]="isEnabled() ? null: 'readOnly'">
                        <option value="" disabled>Seleziona un opzione</option>
                        <option value="1">Si</option>
                        <option value="2">No</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div class="col-12" *ngIf="appform.premi === '1'">
                  <div class="mt-2">Riconoscimenti e premi: Indicare quali*</div>
                  <textarea rows="5" [(ngModel)]="appform.specifica_premi" required
                            placeholder="Premi ricevuti: indicare quali"
                            [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
                </div>
              </div>

            </div>


            <div class="col-md-12 mb-1">
              <div class="bg-gray-2">
                <label>
                  Esiste una relazione tra il progetto d’impresa e il contenuto di ricerca e/o scientifico di conoscenza
                  sviluppato da una Università e/o Ente e/o Centro di ricerca nazionali o internazionali?*
                  <select [(ngModel)]="appform.correlazione" [disabled]="isEnabled() ? null: 'readOnly'">
                    <option value="" disabled>Seleziona un opzione</option>
                    <option value="1">Si</option>
                    <option value="2">No</option>
                  </select>
                </label>
              </div>
            </div>

            <div class="col-12 margin_line" style="border: 1px solid white;"></div>

            <div class="col-12">
              <p class="titolo">Informazioni relative al tuo mercato</p>
            </div>

            <div class="col-12 mb-1">
              <div class="row mt-2">
                <div class="col-8">Quali sono i tuoi principali concorrenti?*</div>
                <div
                  class="col-4 align-end {{appform.concorrenti_mercato.length > 2000 ? 'overlimit' : ''}}">{{appform.concorrenti_mercato.length}}
                  / 2000
                </div>
              </div>
              <textarea rows="5" maxlength="2000" required [(ngModel)]="appform.concorrenti_mercato"
                        placeholder="Descrivi brevemente i principali concorrenti evidenziando per ognuno il prodotto/servizio offerto, il target ed il business model adottato uno per uno includendo i loro collegamenti, il capitale raccolto e, quando possibile, i lori ricavi"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <div class="col-12 mb-1">
              <div class="row mt-2">
                <div class="col-8">In che modo la tua offerta è diversa da quella dei tuoi concorrenti?*</div>
                <div
                  class="col-4 align-end {{appform.differenzazione_concorrenti_startup.length > 2000 ? 'overlimit' : ''}}">{{appform.differenzazione_concorrenti_startup.length}}
                  / 2000
                </div>
              </div>
              <textarea rows="5" maxlength="2000" required [(ngModel)]="appform.differenzazione_concorrenti_startup" placeholder="Descrivi brevemente le principali differenze in termini di caratteristiche del prodotto/servizio offerto, business model adottato, processi di lavorazione/erogazione, canali di distribuzione"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <div class="col-12 margin_line" style="border: 1px solid white;"></div>

            <div class="col-12">
              <p class="titolo">Risultati di impatto sociale</p>
            </div>

            <div class="col-12 mb-1">
              <div class="row mt-2">
                <div class="col-8">Quali risultati avete raggiunto o raggiungerete in termini di impatto sociale con il  vostro progetto?*</div>
                <div
                  class="col-4 align-end {{appform.risultati_impatto_sociale.length > 1500 ? 'overlimit' : ''}}">{{appform.risultati_impatto_sociale.length}}
                  / 1500
                </div>
              </div>
              <textarea rows="5" maxlength="1500" [(ngModel)]="appform.risultati_impatto_sociale" required placeholder="Descrivere le caratteristiche distintive ed innovative del progetto imprenditoriale in termini di sostenibilità sociale"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <div class="col-12 margin_line" style="border: 1px solid white;"></div>

            <div class="col-12">
              <p class="titolo">Risultati di impatto ambientale</p>
            </div>

            <div class="col-12 mb-1">
              <div class="row mt-2">
                <div class="col-8">Quali risultati avete raggiunto o raggiungerete in termini di impatto ambientale con il  vostro progetto?*</div>
                <div
                  class="col-4 align-end {{appform.risultati_impatto_ambientale.length > 1500 ? 'overlimit' : ''}}">{{appform.risultati_impatto_ambientale.length}}
                  / 1500
                </div>
              </div>
              <textarea rows="5" maxlength="1500" [(ngModel)]="appform.risultati_impatto_ambientale" required placeholder="Descrivere le caratteristiche distintive ed innovative del progetto imprenditoriale in termini di sostenibilità ambientale"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <div class="col-12 mb-1" *ngIf="appform.correlazione === '1'">
              <div class="bg-gray-2">
                <label>
                  Università / Ente / Centro*
                  <input [(ngModel)]="appform.correlazione_enti" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Con quale Università e/o Ente e/o Centro di ricerca si è collaborato?">
                </label>
              </div>
            </div>

            <div class="col-12 mb-1" *ngIf="appform.correlazione === '1'">
              <div class="row mt-2">
                <div class="col-8">Descrizione delle caratteristiche della relazione del progetto imprenditoriale con
                  Università/Ente/Centro di ricerca*
                </div>
                <div
                  class="col-4 align-end {{appform.correlazione_descrizione.length > 1000 ? 'overlimit' : ''}}">{{appform.correlazione_descrizione.length}}
                  / 1000
                </div>
              </div>
              <textarea [(ngModel)]="appform.correlazione_descrizione" rows="5" maxlength="1000" required
                        placeholder="Descrivere brevemente le modalità e le caratteristiche della relazione del progetto imprenditoriale con Università/Ente/Centro di ricerca"
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>


            <div class="col-12  mb-5 mt-5">
              <div class="bg-gray-2 ">
                <h2 style="font-size: 32px !important;">
                  Team</h2>

                <input class="hidden" #team type="file" name="team"
                       [disabled]="isEnabled() ? null: 'readOnly'"
                       (change)="handleFileInputTeam($event.target.files, 'team')"/>

              </div>
              <div class="row">

                <div class="col-12 mb-2">
                  <div class="tabellaFile">
                    <div class="row align-items-center justify-content-center mb-1 mt-1"
                         *ngIf="appform.team.length == 0">
                      <div class="col-lg-auto text-center mb-1 mb-lg-0 ">Nessun membro inserito</div>
                    </div>
                    <div class="row file-row align-items-center justify-content-center  mb-1 mt-1"
                         *ngFor="let i of appform.team; index as index">

                      <div class="col">
                        <div class="row">

                          <div class="col-lg-auto text-center mb-2 mb-lg-0 bordo-destra">{{i.nome}} {{i.cognome}}</div>
                          <div class="col-lg-auto text-center mb-2 mb-lg-0 bordo-destra">{{i.ruolo}}</div>
                          <div class="col-lg-auto text-center mb-2 mb-lg-0 bordo-destra">{{i.categoria}}</div>
                          <div class="col-lg-auto text-center mb-2 mb-lg-0 bordo-destra">{{i.ente}}</div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="row">
                          <div class="col-lg-auto text-center align-center" *ngIf="isEnabled()">

                            <button class="btn  btn-cyan" (click)="removeTeamRow(index)">Elimina
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="col-12 mb-2" *ngIf="isEnabled()">
                  <div class="row">
                    <div class="col-12 mb-1">Aggiungi un membro*</div>
                    <div class="col-12 col-md-4 mb-1">
                      <div class="bg-gray-2">
                        <label>
                          <input [(ngModel)]="cur_team_nome" type="text" required placeholder="Nome ">
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-md-4 mb-1">
                      <div class="bg-gray-2">
                        <label>
                          <input [(ngModel)]="cur_team_cognome" type="text" required placeholder="Cognome ">
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-md-4 mb-1">
                      <div class="bg-gray-2">
                        <label>
                          <input [(ngModel)]="cur_team_ruolo" type="text" required placeholder="Ruolo ">
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-md-4 mb-1">
                      <div class="bg-gray-2">
                        <label>
                          <input [(ngModel)]="cur_team_categoria" type="text" required
                                 placeholder="Categoria: docente, ricercatore, laureato, etc.">
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-md-4 mb-1">
                      <div class="bg-gray-2">
                        <label>
                          <input [(ngModel)]="cur_team_ente" type="text" required placeholder="Università / Ente ">
                        </label>
                      </div>
                    </div>

                    <div class="col-12 col-md-4 mb-1">
                      <div class="bg-gray-2">
                        <label style="padding-top: 5px">

                          <button class="btn  btn-cyan" (click)="addTeamClick()" *ngIf="!team_loader">Aggiungi membro
                          </button>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </div>


            <div class="col-12 margin_line" style="border: 1px solid white;"></div>

            <div class="col-12 mt-4">
              <div class="bg-gray-2 mb-1">
                <div class="row">
                  <h2 class="col-lg-8" style="font-size: 32px !important;">Allega Pitch (pdf, ppt, etc. - peso max 15 MB)</h2>
                  <div class="col-lg-4">
                    <input type="file" name="presentazione" [disabled]="isEnabled() ? null: 'readOnly'"
                           (change)="handlerPresentazione($event)" [(ngModel)]="appform.presentazione">
                    <div class="mb-3" *ngIf="isPresentazione" style="color: #f0dc00">Il file di presentazione è stato
                      già caricato!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-4">
              <div class="bg-gray-2 mb-1">
                <div class="row">
                  <h2 class="col-12" style="font-size: 32px !important;">Inviaci un video</h2>
                </div>
              </div>
            </div>

            <div class="col-md-12 mb-1">
              <div class="bg-gray-2">
                <label>
                  Inserisci l'url del video (Youtube, Vimeo, etc.)
                  <input [(ngModel)]="appform.link_youtube" [disabled]="isEnabled() ? null: 'readOnly'" type="text"
                         required placeholder="Inserisci l'url del video (Youtube, Vimeo etc)">
                </label>
              </div>
            </div>

            <div class="col-12 margin_line" style="border: 1px solid white;"></div>

            <div class="col-12 mt-4">
              <div class="bg-gray-2 mb-1">
                <div class="row">
                  <h2 class="col-lg-8" style="font-size: 32px !important;">Carica documento di identità*</h2>
                  <div class="col-lg-4">
                    <input type="file" name="documento_identita" [disabled]="isEnabled() ? null: 'readOnly'"
                           (change)="handlerDocumento($event)" [(ngModel)]="appform.documento_identita">
                    <div *ngIf="isDocumentoIdentita" style="color: #f0dc00">Il documento d'identità è stato già
                      caricato!
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--            <div class="col-12 mt-5">-->
            <!--              <div class="bg-gray-2 mb-1">-->
            <!--                <div class="row">-->
            <!--                  <h2 class="col" style="font-size: 32px !important;">Business Model Canvas</h2>-->
            <!--                  <div class="col-auto">-->
            <!--                    <a class="btn  btn-cyan" href="/assets/canvas_2022.docx" target="_blank">Scarica il modello</a>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div class="col-12 mt-5">-->
            <!--              <div class="bg-gray-2 mb-1">-->
            <!--                <div class="row">-->
            <!--                  <h2 class="col" style="font-size: 32px !important;">Schema Business Plan</h2>-->
            <!--                  <div class="col-auto">-->
            <!--                    <a class="btn  btn-cyan" href="/assets/BUSINESS_PLAN_2022.docx" target="_blank">Scarica il modello</a>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div class="col-12 mb-4 mt-5">-->
            <!--              <div class="bg-gray-2 mb-1">-->
            <!--                <div class="row">-->
            <!--                  <h2 class="col" style="font-size: 32px !important;">Executive Summary</h2>-->
            <!--                  <div class="col-auto">-->
            <!--                    <a class="btn  btn-cyan" href="/assets/EXECUTIVE_SUMMARY_2022.docx" target="_blank">Scarica il modello</a>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="col-12 margin_line" style="border: 1px solid white;"></div>

            <div class="col-12">
              <p class="titolo">Informative finali</p>
            </div>

            <div class="col-12">
              <div class="bg-gray-2 mt-3">
                <p style="font-size: 18px"><strong>I componenti del team dichiarano sotto la propria
                  responsabilità:</strong></p>
                <p><input type="checkbox" [(ngModel)]="cb1" data-toggle="toggle" class="mr-1" style="width: auto">di
                  aver preso visione del Regolamento START CUP Abruzzo 2023 e di accettare tutte le disposizioni in esso
                  contenute;</p>
                <p><input type="checkbox" [(ngModel)]="cb2" data-toggle="toggle" class="mr-1" style="width: auto">di
                  essere in possesso dei requisiti ivi indicati;</p>
                <p><input type="checkbox" [(ngModel)]="cb3" data-toggle="toggle" class="mr-1" style="width: auto">di non
                  essere iscritti e/o di non partecipare, nel corso dell’anno 2023, ad altre Start Cup del circuito
                  PNI-Premio Nazionale per l’Innovazione.</p>
              </div>
            </div>


            <div class="col-12 margin_line" style="border: 1px solid white;"></div>


            <div class="col-12 mb-1">
              <div class="row mt-2">
                <div class="col-8 col-lg-10 mb-3"> Nel caso l’idea presentata partecipi, anche indirettamente, ad altre
                  iniziative o forme di finanziamento (diverse dalle Start Cup del circuito PNI), indicare di seguito i
                  riferimenti.
                </div>
                <div
                  class="col-4 col-lg-2 align-end {{appform.iniziative.length > 1500 ? 'overlimit' : ''}}">{{appform.iniziative.length}}
                  / 1500
                </div>
              </div>
              <textarea [(ngModel)]="appform.iniziative" rows="5" maxlength="1500" required
                        placeholder="Riferimenti."
                        [disabled]="isEnabled() ? null: 'readOnly'"></textarea>
            </div>

            <div class="col-12 mt-4 mb-1">
              <div class="bg-gray-2">
                <p style="font-size: 20px;">
                  <strong>Ai fini della riscossione dell’eventuale premio in denaro, i componenti del team si rendono
                    disponibili:</strong>
                </p>
                <p style="font-size: 16px;">• qualora l’impresa sia già stata costituita, ad avere, alla data del 30 giugno 2024, almeno la sede secondaria o un’unità locale operativa nel territorio di riferimento della Camera di Commercio Chieti Pescara;</p>
                <p style="font-size: 16px;">• qualora l'impresa non sia stata ancora costituita, a costituire, entro e non oltre il 30 giugno 2024, una impresa sotto forma di società di capitali (S.r.l., S.r.l.s. ovvero S.p.a.) che dovrà avere, a tale data, almeno la sede secondaria o un’unità locale operativa nel territorio di riferimento della Camera di Commercio Chieti Pescara.</p>
                <p style="font-size: 16px;">N.B. È possibile che la società costituita sia partecipata da altra società nella cui compagine siano presenti uno o più componenti del team degli aspiranti imprenditori vincitori del premio.</p>
              </div>
            </div>


            <div class="col-12 mt-3 mb-2" style="border: 1px solid white;"></div>

            <div class="col-12 mt-4 mb-1">
              <div class="bg-gray-2">
                <p style="font-size: 20px;">
                  <strong>
                    I documenti (il pdf generato dal sistema e i file scaricati), compilati in ogni loro parte, vanno
                    inviati dalla PEC del Referente principale, in formato pdf, all’indirizzo di posta elettronica
                    cciaa@pec.chpe.camcom.it, specificando nell’oggetto “Domanda di partecipazione StartCup Abruzzo
                    2023”.

                  </strong>
                </p>
              </div>
            </div>

            <div class="col-12 mb-4 mt-3">
              <div class="row">
                <div class="col-auto mb-1" *ngIf="isEnabled()">
                  <div class="bg-gray-2">
                    <button type="button" (click)="onSubmit()" class="btn  btn-cyan">Salva</button>
                  </div>
                </div>

                <div class="col-auto mb-1" *ngIf="isEnabled()">
                  <div class="bg-gray-2">
                    <button type="button" (click)="confermaInvio()" class="btn btn-cyan">Conferma l'invio del progetto
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mb-4 mt-3">
              <div class="row">
                <div class="col-auto mb-1" *ngIf="isEnabled()">
                  <div class="bg-gray-2">
                    <h3 style="font-size: 18px">Per info, dubbi, chiarimenti e/o eventuali problemi di caricamento del
                      tuo progetto puoi scriverci a:
                      <a style="color: #1d92d0 !important;" href="mailto:segreteria@startcupabruzzo.it">segreteria@startcupabruzzo.it</a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
