<!--<div class="container-fluid bg-salmone admin-login-main">-->
<!--  <div class="row">-->
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-md-12 align-center">-->
<!--          <h1 style="font-weight: bold" class="color-white mb-5">Reset password</h1>-->
<!--          <h3 class="color-white">-->
<!--            Inserisci una nuova password-->
<!--          </h3>-->
<!--        </div>-->
<!--        <div class="col-md-12 mt-4 mt-md-0 align-center">-->
<!--          <div class="row">-->
<!--            <div class="col-lg-6">-->
<!--              <form method="post" #f="ngForm" (ngSubmit)="onSubmit(f)">-->
<!--                <input type="password" ngModel name="password" placeholder="Password*" required>-->
<!--                <input type="hidden"  [(ngModel)]="token" name="token"  required>-->
<!--                <a href="#" class="color-red d-block mt-3 mb-3" style="font-size: 13px"></a>-->
<!--                <button type="submit" class="btn btn-red">Salva</button>-->
<!--              </form>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<div class="container bg-salmone admin-login-main">
  <div class="row">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <img style="max-width: 200px" src="./assets/logoWhite.svg" class="logo-big" alt="">
        </div>

      </div>
      <div class="row">
        <div class="col-md-12 mt-4 mt-md-0 align-center">
          <h2 class="color-white">
            Inserisci una nuova password
          </h2>
        </div>
        <div class="col-lg-6 offset-lg-3">
          <div class="row">

            <div class="col-md-12 mt-4 mt-md-0">
              <div class="row appform">
                <div class="col-lg-12">
                  <form method="post" class="row" #f="ngForm" (ngSubmit)="onSubmit(f)">


                    <div class="col-12">
                      <div class="bg-gray-2">
                        <label>
                          Password*
                          <input type="password" ngModel name="password" class="mb-4" placeholder="Password*" required>
                        </label>
                      </div>
                    </div>
                    <input type="hidden"  [(ngModel)]="token" name="token"  required>

                    <div class="col-12">
                      <div class="row align-items-center justify-content-center">
                        <div class="col-auto">
                          <button type="submit" class="btn btn-cyan">Salva</button>
                          <mat-progress-spinner color="primary" *ngIf="showLoader" diameter="30"
                                                mode="indeterminate"></mat-progress-spinner>
                        </div>

                      </div>
                    </div>


                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
