<div class="container bg-salmone admin-login-main home-container">
  <div class="row">
    <div class="col-12 text-center">
      <img class="logo-big" src="./assets/logoWhite.svg" alt="">
    </div>
  </div>
  <div class="row mt-3 align-items-center justify-content-center" *ngIf="username === ''">
    <h3 style="font-size: 20px" class="col-12 text-center">
      Per inviare il tuo progetto
    </h3>
    <div class="col-auto">
      <a class="btn btn-cyan" routerLink="/registrazione">REGISTRATI</a>
    </div>
    <div class="col-auto">
      oppure
    </div>
    <div class="col-auto">
      <a class="btn btn-cyan" routerLink="/login">ACCEDI</a>
    </div>
  </div>

  <div class="row mt-3 align-items-center justify-content-center" *ngIf="username !== ''">
    <div class="col-auto">
      <a class="btn btn-cyan" routerLink="/appform">VAI AL PROGETTO</a>
    </div>
  </div>
</div>
