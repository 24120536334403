import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import {Observable} from "rxjs";
import {Consts} from "./Consts";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(request.method, request.url)
    let method = request.method == 'PUT' || request.method == 'POST'
    let url = request.url.indexOf('/admin/eventi/') > -1
    var basicHeader = {
      Authorization: 'Bearer ' + localStorage.getItem(Consts.TOKEN),
    }
    if (method && url) {
    }
    request = request.clone({
      setHeaders: basicHeader
    });
    return next.handle(request);
  }
}
