<div class="container-fluid bg-salmone admin-login-main">
  <div class="row">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h1 style="font-weight: bold" class="color-white mb-5">Il mio account</h1>

        </div>

      </div>
      <div class="row">

        <div class="col-md-12 mt-4 mt-md-0">
          <div class="row">
            <div class="col-12 ">


                <div class="row appform">
                  <div class="col-6">
                    <div class="bg-gray-2">
                      <label>
                        Nome*
                        <input type="text" [(ngModel)]="user.nome" name="nome" class="mb-4" placeholder="Nome*" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="bg-gray-2">
                      <label>
                        Cognome*
                        <input type="text" [(ngModel)]="user.cognome" name="cognome" class="mb-4" placeholder="Cognome*" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Data di nascita*
                        <input type="date" placeholder="Data di nascita" [(ngModel)]="user.data_nascita" name="data_nascita" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Nato a*
                        <input type="text" placeholder="Nato a" [(ngModel)]="user.nato_a" name="nato_a" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="bg-gray-2">
                      <label>
                        Provincia*
                        <input type="text" placeholder="Provincia" [(ngModel)]="user.provincia_nascita" name="provincia_nascita" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Città di residenza*
                        <input type="text" placeholder="Città di residenza" [(ngModel)]="user.citta_residenza" name="citta_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Provincia di residenza*
                        <input type="text" placeholder="Provincia di residenza" [(ngModel)]="user.provincia_residenza" name="provincia_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Via di residenza*
                        <input type="text" placeholder="Via di residenza" [(ngModel)]="user.via_residenza" name="via_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Numero di residenza*
                        <input type="text" placeholder="Numero di residenza" [(ngModel)]="user.numero_residenza" name="numero_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Cap di residenza*
                        <input type="text" placeholder="Cap di residenza" [(ngModel)]="user.cap_residenza" name="cap_residenza" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-12 margin_line" style="border: 1px solid white;"></div>


                  <div class="col-12 mb-3">
                    <p style="font-size: 20px">Inserisci il domicilio o recapito per comunicazioni, se è diverso dalla residenza:</p>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Città di domicilio
                        <input type="text" placeholder="Città di domicilio" [(ngModel)]="user.citta_domicilio" name="citta_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Provincia di domicilio
                        <input type="text" placeholder="Provincia di domicilio" [(ngModel)]="user.provincia_domicilio" name="provincia_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Via di domicilio
                        <input type="text" placeholder="Via di domicilio" [(ngModel)]="user.via_domicilio" name="via_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Numero di domicilio
                        <input type="text" placeholder="Numero di domicilio" [(ngModel)]="user.numero_domicilio" name="numero_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="bg-gray-2">
                      <label>
                        Cap di domicilio
                        <input type="text" placeholder="Cap di domicilio" [(ngModel)]="user.cap_domicilio" name="cap_domicilio" class="mb-4">
                      </label>
                    </div>
                  </div>

                  <div class="col-12 margin_line" style="border: 1px solid white;"></div>


                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        Codice fiscale*
                        <input type="text" placeholder="Codice fiscale" [(ngModel)]="user.cf" name="cf" class="mb-4" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="bg-gray-2">
                      <label>
                        Telefono*
                        <input type="text" [(ngModel)]="user.telefono" name="telefono" class="mb-4" placeholder="Telefono*" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="bg-gray-2">
                      <label>
                        Email*
                        <input type="email" [(ngModel)]="user.email" readonly name="email" class="mb-4 readonly" placeholder="Email*" required>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="bg-gray-2">
                      <label>
                        PEC*
                        <input type="email" [(ngModel)]="user.pec" placeholder="PEC" name="pec" class="mb-4" required>
                      </label>
                    </div>
                  </div>

<!--                  <div class="col-6">-->
<!--                    <div class="bg-gray-2">-->
<!--                      <label>-->
<!--                        Password*-->
<!--                        <input type="password" [(ngModel)]="user." name="password" class="mb-4" placeholder="Password*" required>-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div class="col-6"></div>-->
                  <div class="col-6">
                    <div class="bg-gray-2">
                      <label>
                        Regione*
                        <select ngModel class="mb-4" name="regione" required [(ngModel)]="user.regione" (change)="onSelectChangeRegione()">
                          <option value="-1">Estero</option>
                          <option *ngFor="let i of regioni" value="{{i.id}}">{{i.nome}}</option>
                        </select>
                      </label>
                    </div>
                  </div>

                  <div class="col-6" *ngIf="user.regione > 0">
                    <div class="bg-gray-2">
                      <label>
                        Provincia
                        <select ngModel class="mb-4" name="provincia" [(ngModel)]="user.provincia">
                          <option *ngFor="let i of province" value="{{i.id}}">{{i.nome}}</option>
                        </select>
                      </label>
                    </div>
                  </div>


<!--                  <div class="col-12">-->
<!--                    <div class="bg-gray-2">-->
<!--                      <label>-->
<!--                        Indirizzo*-->
<!--                        <input [(ngModel)]="user.indirizzo" type="text" name="indirizzo" class="mb-4" placeholder="Indirizzo"  >-->
<!--                      </label>-->
<!--                    </div>-->
<!--                  </div>-->

                  <div class="col-12">
                    <div class="bg-gray-2 row-privacy">

                      <input name="privacy" type="checkbox" id="privacy-1" value="1"  [(ngModel)]="user.privacy" disabled>
                      <label for="privacy-1" required>*Dichiaro di aver preso visione dell’Informativa Privacy disponibile sul sito
                        alla pagina <a target="_blank" href="/privacy">privacy</a>.
                        Ai sensi del Considerando 32 e dell’art. 7 GDPR del Regolamento UE 2016/679, presto esplicito
                        consenso
                        al trattamento dei dati personali forniti, raccolti ed utilizzati secondo le procedure,
                        per le finalità e per il periodo necessario alla gestione del presente evento.</label>
                    </div>
                  </div>

                  <div class="col-12 align-center" *ngIf="!showLoader" >
<!--                    <button type="submit" class="btn btn-red">Salva</button>-->
                    <button type="button" (click)="onSubmit()" class="btn btn-red">Salva</button>
                  </div>

                  <div class="col-12 align-center" *ngIf="showLoader">
                    <mat-progress-spinner diameter="30" mode="indeterminate"></mat-progress-spinner>
                  </div>

                </div>


            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
