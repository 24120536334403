import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {ApiService} from "../api.service";
import {Router} from "@angular/router";
import {Consts} from "../Consts";
import {Regione} from "../model/regione";
import {Provincia} from "../model/provincia";
import {AlertDialogComponent} from "../alert-dialog/alert-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.less']
})
export class RegistrationComponent implements OnInit {
  @ViewChild('f') f: NgForm;

  regioni: Array<Regione> = Array<Regione>()
  province: Array<Provincia> = Array<Provincia>()
  showLoader = false;
  iscrizioni_chiuse = false
  constructor(private api: ApiService, private router: Router, public dialog: MatDialog) {
  }

  ngOnInit(): void {
    var username = localStorage.getItem(Consts.USERNAME) || ""
    if(username !== "")
      this.router.navigate(['/account'])
    // this.regioni = Consts.regioni

    this.api.localita().subscribe(res => {
      if (res['success']) {
        this.regioni = res['items']
        if (res['chiuso'])
          this.iscrizioni_chiuse = true
      }
    })
  }


  onSelectChange(e) {
    var regione = this.regioni.find(el => el.id == e)
    this.province = regione.province
  }
  isValidMail(mail){
    return Consts.mailformat.test(mail)

  }

  onSubmit(f: NgForm) {
    console.log(f.value)

    if(f.value.privacy === "")
    {
      // alert("Accetta la privacy per continuare")
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Accetta la privacy per continuare.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }
    if(!this.isValidMail(this.f.value.email)){
      // alert("Formato email non valido.")
      // return
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Formato email non valido.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    if(!this.isValidMail(this.f.value.pec)){
      // alert("Formato email non valido.")
      // return
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Formato pec non valido.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    if(this.f.value.password !== this.f.value.repassword){

      // alert("Formato email non valido.")
      // return
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"La password non coincide.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    if(this.f.value.regione >0 &&  this.f.value.provincia == ""){

      // alert("Formato email non valido.")
      // return
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Seleziona una provincia.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
      return
    }

    if (f.valid) {
      this.showLoader = true
      this.api.registration(f.value).subscribe((res) => {
        if (res.success && res.token) {
          localStorage.setItem(Consts.TOKEN, res.token)
          this.api.me().subscribe(res => {
            if (res['success']) {
              localStorage.setItem(Consts.USERNAME, res['me']['username'])
              localStorage.setItem(Consts.EXPIRATION, res['me']['expiration'])
              localStorage.setItem(Consts.ROLE, res['me']['role'])
              // location.reload()
              this.router.navigate(['/appform']).then(() => {
                window.location.reload();
              });
            }
            this.showLoader = false
          })
        }
        else {
          this.showLoader = false
          const dialogRef = this.dialog.open(AlertDialogComponent, {
            width: '450px',
            data: {
              titolo:"Attenzione",
              testo:res.message,
              testo2:"",
              hasCancel:false,
              hasConfirmProject:false
            }
          });
        }
      })
    }
    else{
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        width: '450px',
        data: {
          titolo:"Attenzione",
          testo:"Riempi tutti i campi obbligatori.",
          testo2:"",
          hasCancel:false,
          hasConfirmProject:false
        }
      });
    }
      // alert("Riempi tutti i campi obbligatori.")
  }
}
